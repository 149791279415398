type Props = {
  className: string
  description: string
  color: string
  img: string
  valorGanho?: any
  valorReceber?: any
  tetoPlano?: any
  prctGanhos?: any
  progresso?: any
  config?: any
  assinatura?: any
  subscription?: any
  loaded?: any,
}

const CardsWidget20 = ({
  className,
  description,
  color,
  img,
  valorGanho,
  valorReceber,
  tetoPlano,
  prctGanhos,
  progresso,
  assinatura,
  config,
  subscription,
  loaded,
}: Props) => {
  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className} ${
        loaded ? '' : 'overlay overlay-block'
      }`}
      style={{
        backgroundColor: "#ffad0f",
        // backgroundImage: `url('${img}')`,
      }}
    >
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>
            {loaded ? (
              <>{assinatura ? `${config.moeda.prefixo} ${parseFloat(valorGanho).toFixed(2)}` : 'Sem Assinatura!'}</>
            ) : (
              <></>
            )}
          </span>

          <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>
            {loaded ? (
              <>{assinatura ? description : 'Assine um plano para começar seus rendimentos.'}</>
            ) : (
              <></>
            )}
          </span>
        </div>
      </div>
      <div className='card-body d-flex flex-column justify-content-end pt-0'>
        <div className='overlay-wrapper'>
          <div className='d-flex flex-column mb-2 w-100'>
            <span className='text-white opacity-75'>
              {loaded && assinatura ? <>{subscription.dias_restantes} dias restantes</> : <></>}
            </span>
            <span className='text-white opacity-75'>
              {loaded && assinatura ? <>Iniciado {subscription.data_resposta}</> : <></>}
            </span>
          </div>
          <div className='d-flex align-items-center flex-column mt-3 w-100'>
            <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
              <span>
                {loaded && assinatura ? (
                  <>
                    {config.moeda.prefixo} {parseFloat(valorGanho).toFixed(2)} / {config.moeda.prefixo} {parseFloat(tetoPlano).toFixed(2)}
                  </>
                ) : (
                  <></>
                )}
              </span>
              <span>{loaded && assinatura ? <>{prctGanhos}%</> : <></>}</span>
            </div>
            <div className='h-10px mx-3 w-100 bg-white bg-opacity-50 rounded'>
              {loaded && assinatura ? (
                <div
                  className='bg-white rounded h-10px'
                  role='progressbar'
                  style={{width: prctGanhos + '%'}}
                  aria-valuenow={prctGanhos}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {!loaded && (
          <div className='overlay-layer rounded bg-dark bg-opacity-5'>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Carregando...</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export {CardsWidget20}
